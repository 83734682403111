.title{
    font-size: 20px;
    line-height: 155%;
    align-items: center;
    color: #000000;
}
.content{
    font-size: 14px;
    line-height: 24px;
    color: #4F4F4F;
}