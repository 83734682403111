.file-input-wrapper {
    width: 200px;
    height: 80px;
    overflow: hidden;
    position: relative;
}
.file-input-wrapper > input[type="file"] {
    font-size: 200px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
}
.file-input-wrapper:hover > .btn-file-input {
    background-color: #aaa;
}
.upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
    align-items: center;
}