.title{
    font-size: 1.25rem;
    line-height: 155%;
    align-items: center;
    color: #000000;
}
.content{
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #4F4F4F;
}

