/* Tree node container */
.tree-node {
    margin-left: 20px;
    margin-bottom: 10px;
    font-family: Arial, sans-serif;
  }
  
  /* Tree node label */
  .tree-node-label {
    cursor: pointer;
    font-size: 18px; /* Larger text for category and subcategory names */
    font-weight: bold;
    color: #333; /* Darker color for the text */
    display: flex;
    align-items: left;
    padding: 5px 0;
  }
  
  /* Documents list */
  .documents-list {
    list-style-type: none;
    padding-left: 20px;
  }
  
  /* Individual document item */
  .document-item {
    font-size: 16px;
    height: 40px;
    color: #555; /* Slightly lighter for document names */
    margin: 5px 0;
  }
  
  /* Link for document */
  .document-item a {
    color: #1e90ff;
    text-decoration: none;
    font-size: 14px;
  }
  
  /* Link hover effect */
  .document-item a:hover {
    text-decoration: underline;
  }
  
  /* Tree container */
  .documents-tree-container {
    
    margin: 20px 0;
    padding: 10px;
    /* border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9; */
  }
  
  /* Visual indicator for expanded/collapsed */
  .tree-node-label::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  
  